import { startTransition } from 'react';

import { RemixBrowser } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';

import { playNowLayoutId } from './play-now/routes/play-now-layout-id';

startTransition(() => {
  // redirect empty pathnames to home (like https://play.swissinfo.ch/)
  if (window.location.pathname === '/') {
    window.location.href = '/play/tv';
  }

  if (__PLAY__.isRequestedViaCDN) {
    // Adjust the path of the parent route for the active BU when the application is requested via CDN.
    // This strips the business unit prefix from the path and allows the client app to run
    // on /play/tv instead of /:businessUnit/:environment?/play/tv
    __remixManifest.routes[playNowLayoutId].path = '/play';
  }

  hydrateRoot(document, <RemixBrowser />);

  // Add client-side only imports after hydration to avoid mismatch of client and server-side rendered app.
  import('@play-now/video/default-imports');
});
